
import { ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { email, helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import AuthService from "@/services/AuthService"
import { state } from "@/utils/state"
import { vsolCompanyId } from "@/constants/companies"

export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const loggingIn = ref(false)
    const error = ref(false)

    const form = ref({
      email: "",
      password: "",
    })

    const rules = {
      email: {
        emailRequired: helpers.withMessage("The email address is required", required),
        emailWellFormed: helpers.withMessage("Not a valid email address", email),
      },
      password: {
        ownerRequired: helpers.withMessage("The password is required", required),
      },
    }

    const v$: any = useVuelidate(rules, form)

    const onLogin = () => {
      v$.value.$touch()

      if (!v$.value.$invalid) {
        loggingIn.value = true

        let service = new AuthService()
        service.logIn(form.value.email, form.value.password).then(
          (userResponse) => {
            store.dispatch("logIn", userResponse)
            state.isVsolEmployee = store.getters.getRoles
              ? !!store.getters.getRoles[vsolCompanyId]
              : false
            router.push({ name: "org" })
          },
          (result) => {
            loggingIn.value = false
            error.value = true
          }
        )
      }
    }

    return {
      v$,
      error,
      loggingIn,
      onLogin,
    }
  },
}
